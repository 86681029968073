import React from "react";

function Privacy() {
  return (
    <div className="about">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-1">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              src="http://placehold.it/900x400"
              alt=""
            />
          </div>
          <div className="col-lg-11">
            <a name="menu"></a>
            <h1 className="font-weight-light">Privacy Policy</h1>
            <p>
              This is the privacy policy for the Hup application (
              <strong>the App</strong>). The App is operated and provided by Why
              The Hecky Not Limited (<strong>we</strong>, <strong>us</strong>{" "}
              and <strong>our</strong>). For the purposes of the General Data
              Protection Regulation 2016 and the Data Protection Act 2018, we
              are the data controller and this policy sets out our commitment to
              protecting your privacy by explaining how we use and collect your
              personal information within our Application and what controls you
              have.
            </p>
            <p>
              Please read our policy or select from the different section
              headings below to learn more:
            </p>
            <p>
              1.{" "}
              <a
                title="https://www.whytheheckynot.com/privacy#personalinfo"
                href="#personalinfo"
                target="_self"
              >
                Personal information we may collect from you and how we use it
              </a>
            </p>
            <p>
              2.{" "}
              <a
                title="https://www.whytheheckynot.com/privacy#marketing"
                href="#marketing"
                target="_self"
              >
                Your personal information and marketing
              </a>
            </p>
            <p>
              3.{" "}
              <a
                title="https://www.whytheheckynot.com/privacy#datastorage"
                href="#datastorage"
                target="_self"
              >
                Data storage{" "}
              </a>
            </p>
            <p>
              4.{" "}
              <a
                title="https://www.whytheheckynot.com/privacy#infosharing"
                href="#infosharing"
                target="_self"
              >
                Information sharing
              </a>
            </p>
            <p>
              5.{" "}
              <a
                title="https://www.whytheheckynot.com/privacy#security"
                href="#security"
                target="_self"
              >
                Security and safety
              </a>
            </p>
            <p>
              6.{" "}
              <a
                title="https://www.whytheheckynot.com/privacy#yourrights"
                href="#yourrights"
                target="_self"
              >
                Your rights
              </a>
            </p>
            <p>
              7.{" "}
              <a
                title="https://www.whytheheckynot.com/privacy#aboutwhytheheckynot"
                href="#aboutwhytheheckynot"
                target="_self"
              >
                About Why The Hecky Not Limited
              </a>
            </p>
            <p>
              8.{" "}
              <a
                title="https://www.whytheheckynot.com/privacy#policychanges"
                href="#policychanges"
                target="_self"
              >
                Changes to this Policy
              </a>
            </p>
            <a name="personalinfo">
              <p>
                <strong>
                  1. Personal information we may collect from you and how we use
                  it
                </strong>
              </p>
            </a>
            <p>
              <strong>User Registration</strong>
            </p>
            <p>
              <em>The options and what this means:</em>
            </p>
            <p>
              In order to securely access application content hosted on our
              servers, all users must be authenticated. There are two ways this
              is done as follows:{" "}
            </p>
            <p>i. Anonymous Registrations</p>
            <p>
              This is the default method and no personal information is
              collected or retained. A user-id is generated which allows you to
              securely access hosted content. No other information is retained
              by us, and if the App is uninstalled, then it is possible that all
              associated purchases and user content associated with the original
              anonymous account could be lost. In addition, anonymous
              registration means that you are unable to link any devices to an
              anonymous account, that we are unable to contact you with
              information about content and feature updates.
            </p>
            <p>ii. Email Registration</p>
            <p>
              In order to support restoring purchases, once you have purchased a
              subscription (either recurring or non-recurring), you will be give
              an opportunity to upgrade the anonymous account to an email
              registered account. This allows you to restore purchases and may
              provide access to your previously uploaded content. All email
              addresses are encrypted on our server.
            </p>
            <p>
              <strong>The information you submit</strong>
            </p>
            <p>
              <em>Information we may collect about you:</em>
            </p>
            <ul>
              <li>
                <span>
                  A userId or a userId and encrypted email when you register as
                  a user of the Application.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  In connection with your user account sign-in facility, your
                  login and password details.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  Details of any requests made by you within our Apps, to
                  contact us or where you subscribe to our email newsletters.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  Details of your usage of the App such as how you navigate and
                  use our content.
                </span>
              </li>
            </ul>
            <div>
              <span>
                <br />
              </span>
            </div>
            <div>
              <span>
                <br />
              </span>
            </div>
            <p>
              <em>Some examples of information </em>
            </p>
            <ul>
              <li>
                <span>
                  Email address used to log into the App, which shall be
                  encrypted on our server
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  App usage and navigation - which content you access and how
                  you use that content
                </span>
              </li>
            </ul>
            <div>
              <span>
                <br />
              </span>
            </div>
            <p>
              <strong>Device information</strong>
            </p>
            <p>
              <em>How we might collect</em>
            </p>
            <ul>
              <li>
                <span>
                  We may collect certain technical information when you use your
                  device, to access or use the Application.
                </span>
              </li>
            </ul>
            <div>
              <span>
                <br />
              </span>
            </div>
            <p>
              <em>Some examples of information</em>
            </p>
            <ul>
              <li>
                <span>Device type</span>
              </li>
            </ul>

            <ul>
              <li>
                <span>Operating system and operating version</span>
              </li>
            </ul>
            <ul>
              <li>
                <span>Registration details, including username and email</span>
              </li>
            </ul>
            <ul>
              <li>
                <span>In-app usage, including time, play history etc.</span>
              </li>
            </ul>
            <div>
              <span>
                <br />
              </span>
            </div>
            <p>
              This information helps us to provide an effective service through
              the Application and to collect statistical information about how
              our content is used.
            </p>
            <p>
              <strong>Log information</strong>
            </p>
            <p>
              <em>How collected</em>
            </p>
            <ul>
              <li>
                <span>When you use the App</span>
              </li>
            </ul>

            <p>
              <em>Some examples of information:</em>
            </p>
            <ul>
              <li>
                <span>Registration details to the App. </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  Date and time the App is opened, including session length of
                  play-time within the App.{" "}
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  Areas of the App you visited, including features you used.{" "}
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  Statistical data about your interaction with the App so that
                  we can improve the content we provide.{" "}
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  When your device talks to our server to synchronise your
                  account, purchases or talks to our system to tell us about
                  your installation and the service you receive.
                </span>
              </li>
            </ul>
            <div>
              <span>
                <br />
              </span>
            </div>
            <p>
              <strong>Analytical tools/code</strong>
            </p>
            <p>
              The Applications may use third party or in-app code/technology to
              collect information about access or use of any Application.
            </p>
            <p>
              <em>List of analytic tools: </em>
            </p>
            <p>
              Firebase Crashlytics for assisting in diagnosing the cause of
              crashes. For more information about their analytics technology,
              visit
              <a
                href="https://firebase.google.com/docs/ios/app-store-data-collection#firebasecrashlytics"
                title="https://firebase.google.com/docs/ios/app-store-data-collection#firebasecrashlytics"
              >
                {" "}
                https://firebase.google.com/docs/ios/app-store-data-collection#firebasecrashlytics
              </a>
            </p>
            <p>
              By continuing to use the Application, you consent to our App
              device identifier settings.
            </p>
            <p>
              <strong>What do we use the information for?</strong>
            </p>
            <p>
              <em>Some examples</em>
            </p>
            <ul>
              <li>
                <span>
                  To enable us to manage and maintain your account with regards
                  to state of play, purchases and updates, including all
                  associated devices linked to your account details.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  To provide you with the functions and features of the App.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  To enable us to ensure our content works with your devices and
                  operates to the optimum performance of your device.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  Ability to contact you to inform you of updates, or new
                  content and/or features.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  Ability to fully provide you with all features of the Apps,
                  including support sites, and customer support contact routes.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  We will not share your data or information with any third
                  parties.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  Internal operations to allow troubleshooting, data analysis of
                  App usage, testing, research and service improvements.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  Ability to verify your account and/or registration details,
                  including authentication of your email when registering to use
                  the Apps.
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>Ability to understand your in-app preferences.</span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  To match, where relevant, data received from app stores and
                  associated devices to your account, for example to restore
                  purchase.
                </span>
              </li>
            </ul>
            <p>
              <strong>
                <a
                  title="https://www.whytheheckynot.com/privacy#menu"
                  href="#menu"
                  target="_self"
                >
                  Back to menu
                </a>
              </strong>
            </p>
            <br />
            <hr />
            <br />
            <a name="marketing">
              <p>
                <strong>2. Your personal information and marketing</strong>
              </p>
            </a>
            <p>
              <strong>Account and associated email</strong>
            </p>
            <p>
              If you do not want to receive marketing communications from us to
              your account email address, please simply unsubscribe at the base
              of the email, or contact us via{" "}
              <a
                href="mailto:unsubscribe@whytheheckynot.com"
                title="mailto:unsubscribe@whytheheckynot.com"
                target="_blank"
                rel="noreferrer"
              >
                unsubscribe@whytheheckynot.com
              </a>
            </p>
            <p>
              <strong>
                <a
                  title="https://www.whytheheckynot.com/privacy#menu"
                  href="#menu"
                  target="_self"
                >
                  Back to menu
                </a>
              </strong>
            </p>
            <br />
            <hr />
            <br />
            <a name="datastorage">
              <p>
                <strong>3. Data storage </strong>
              </p>
            </a>
            <p>
              <strong>Where your information is stored</strong>
            </p>
            <p>
              All personal information we collect from you is stored on a secure
              Firebase Web Server, with emails and unique device information
              being encrypted, which may be processed, transferred and/or stored
              outside of the European Economic Area (EEA). If we transfer your
              information outside the EEA in this way, we will take steps to
              ensure that your privacy rights continue to be protected.{" "}
            </p>
            <p>
              <strong>
                <a
                  title="https://www.whytheheckynot.com/privacy#menu"
                  href="#menu"
                  target="_self"
                >
                  Back to menu
                </a>
              </strong>
            </p>
            <br />
            <hr />
            <br />
            <a name="infosharing">
              <p>
                <strong>4. Information sharing</strong>
              </p>
            </a>
            <p>
              <strong>Disclosure of your information</strong>
            </p>
            <p>
              We do not share your personal information with any third party
              marketers.
            </p>
            <p>
              <strong>
                <a
                  title="https://www.whytheheckynot.com/privacy#menu"
                  href="#menu"
                  target="_self"
                >
                  Back to menu
                </a>
              </strong>
            </p>
            <br />
            <hr />
            <br />
            <a name="security">
              <p>
                <strong>5. Security and safety</strong>
              </p>
            </a>
            <p>
              <strong>Security</strong>
            </p>
            <p>
              We understand the importance of ensuring all personal information
              associated with our users is safeguarded and whilst we take steps
              to ensure the security of your information, there is a risk that
              any information transmitted over the internet and stored on a
              computer may be intercepted or accessed by an unauthorised party.
              If you think that someone has accessed your information held by us
              without your permission or gained unauthorised access to your
              login details, you must notify us at{" "}
              <a
                href="mailto:privacy@whytheheckynot.com.com"
                title="mailto:privacy@whytheheckynot.com"
                target="_blank"
                rel="noreferrer"
              >
                privacy@whytheheckynot.com
              </a>
              .
            </p>
            <p>
              <strong>Child safety</strong>
            </p>
            <p>
              If you are aged 18 or under, please get your parent/guardian's
              permission before you provide any personal information, including
              email address, to Why The Hecky Not Ltd.
            </p>
            <p>
              <strong>
                Other app stores or sites that are not operated or managed by
                us, or not ours
              </strong>
            </p>
            <p>
              App stores will have their own privacy policies. We recommend that
              you read these sites or app store terms. We are not responsible
              for the privacy policies or the content of such sites.
            </p>
            <p>
              <strong>
                <a
                  title="https://www.whytheheckynot.com/privacy#menu"
                  href="#menu"
                  target="_self"
                >
                  Back to menu
                </a>
              </strong>
            </p>
            <br />
            <hr />
            <br />
            <a name="yourrights">
              <p>
                <strong>6. Your rights</strong>
              </p>
            </a>
            <p>
              Under the data protection law, you have a number of rights with
              respect to your personal information which are:
            </p>
            <ul>
              <li>
                <span>
                  <strong>Correcting your information</strong> <br />
                  You are entitled to have your personal information updated to
                  ensure it is up-to-date and accurate. This can be done by
                  following the instructions below.{" "}
                </span>
              </li>
            </ul>
            <div>
              <span>
                <br />
              </span>
            </div>
            <ul>
              <li>
                <span>
                  <strong>Deleting your information</strong> <br />
                  You can request that we delete personal information in certain
                  circumstances. These will be specific to each case.{" "}
                </span>
              </li>
            </ul>
            <div>
              <span>
                <br />
              </span>
            </div>
            <p>
              You can action any of these rights by contacting our Customer
              Services department or by contacting us by email at{" "}
              <a
                href="mailto:privacy@whytheheckynot.com"
                title="mailto:privacy@whytheheckynot.com"
                target="_blank"
                rel="noreferrer"
              >
                privacy@whytheheckynot.com
              </a>{" "}
            </p>
            <p>
              <strong>
                <a
                  title="https://www.whytheheckynot.com/privacy#menu"
                  href="#menu"
                  target="_self"
                >
                  Back to menu
                </a>
              </strong>
            </p>
            <br />
            <hr />
            <br />
            <a name="aboutwhytheheckynot">
              <p>
                <strong>7. About Why The Hecky Not Ltd</strong>
              </p>
            </a>
            <p>
              Why The Hecky Not Ltd is the data controller for the purposes of
              the General Data Protection Regulation 2016 and the Data
              Protection Act 2018. If you have any questions about privacy, or
              wish to update your details or action any of your other data
              rights at any time, please contact us using{" "}
              <a
                href="mailto:privacy@whytheheckynot.com"
                title="mailto:privacy@whytheheckynot.com"
                target="_blank"
                rel="noreferrer"
              >
                privacy@whytheheckynot.com
              </a>
            </p>
            <p>
              <strong>
                <a
                  title="https://www.whytheheckynot.com/privacy#menu"
                  href="#menu"
                  target="_self"
                >
                  Back to menu
                </a>
              </strong>
            </p>
            <br />
            <hr />
            <br />
            <a name="policychanges">
              <p>
                <strong>8. Changes to this Policy</strong>
              </p>
            </a>
            <p>
              We may change our Privacy Policy from time to time, so please
              check back regularly. We will try to notify you in advance where
              there are any significant or very important changes.
            </p>
            <p>
              <strong>
                <a
                  title="https://www.whytheheckynot.com/privacy#menu"
                  href="#menu"
                  target="_self"
                >
                  Back to menu
                </a>
              </strong>
            </p>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
