import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  WhyHup,
  GettingStarted,
  FeedbackForm,
  Support,
  About,
  Privacy,
} from "./components";

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/whyHup" exact component={() => <WhyHup />} />
          <Route
            path="/gettingStarted"
            exact
            component={() => <GettingStarted />}
          />
          <Route path="/support" exact component={() => <Support />} />
          <Route path="/privacy" exact component={() => <Privacy />} />
          <Route path="/about" exact component={() => <About />} />
          <Route path="/feedback" exact component={() => <FeedbackForm />} />
        </Switch>
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=a6225df0-82b2-42e8-ae03-c76f788a67ea"
        >
          {" "}
        </script>
        // <Footer />
      </Router>
    </div>
  );
}

export default App;
