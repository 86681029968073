import React from "react";
import { Button, Alert } from "react-bootstrap";
import Zendesk from "react-zendesk";
const ZENDESK_KEY = "a6225df0-82b2-42e8-ae03-c76f788a67ea";

// import {ZohoFeedback}  from "./";
// import {FreshDeskFeedback}  from "./";
function Support2() {
  return (
    <div className="support">
      <div className="zohoASAP">
        <script
          type="text/javascript"
          nonce="{place_your_nonce_value_here}"
          src="https://desk.zoho.com.au/portal/api/web/inapp/7448000000093065?orgId=7001561046"
          defer
        ></script>
      </div>
    </div>
  );
}

function Support() {
  const setting = {
    color: {
      theme: "#FF69B4",
      launcher: "#FFA500", // This will also update the badge
      launcherText: "#000000",
      button: "#8A0648",
      resultLists: "#691840",
      header: "#203D9D",
      articleLinks: "#FF4500",
    },

    chat: {
      suppress: false,
      notifications: {
        mobile: {
          disable: true,
        },
      },
    },
  };
  return (
    <div className="support">
      <Alert variant="primary">
        <Alert.Heading>19 May 2022</Alert.Heading>
        If you can't find what you're looking for, let us know using the Help
        button at bottom of page and we'll get back to you.
      </Alert>

      <div className="container">
        <div className="row align-items-start my-5">
          <div className="col-lg-6 ">
            <h3 className="font-weight-light">FAQ</h3>
            <ol>
              <li>
                <p> What kind of feedback are you looking for ? </p>
                <p>
                  {" "}
                  All feedback is welcome. I'm particularly interested in
                  whether you find the practice modes useful and what you find
                  difficult, inconvenient or annoying.{" "}
                </p>
                <div className="text-center">
                  <Button variant="primary" size="lg" href="./feedback">
                    Feedback
                  </Button>{" "}
                </div>
              </li>
              <li>
                <p>My phone hasn't got headphone socket. How can I use Hup?</p>
                <p>
                  The app works best with wired headphones. For most iPhones
                  newer than iPhone 6, you'll need a lightning to 3.5 mm
                  headphone adapter cable. (less than $10 online). You can use
                  your phones speaker and mic, but the experience isn't as
                  enjoyable - the mic is too close to the phone speaker.
                  Bluetooth is not supported because the audio lag is
                  unpredictable.
                </p>
              </li>
              <li>
                <p>In the practice loops, I don't know when to start playing</p>
                <p>
                  The Hup recordings used fixed timing, so you need to start
                  playing immediately after the listen phase. Initially, it may
                  be helpful to reduce the tempo and use a 2-bar loop. It might
                  also be useful to just play along with the listen phase and
                  keep playing. Alternatively you can use the track mixer to
                  include the melody or accompaniment during the play phase. If
                  you're looking at the screen, play when the green portion of
                  the coloured ring arrvies at 12 o clock. Like a metronome, it
                  can take a bit of practice.
                </p>
              </li>
              <li>
                <p>Why does Hup have so many different practice modes?</p>
                <p>
                  The video tour goes into some detail on the different modes
                  and how they can be used. The short answer is that I have
                  found that I spend longer focussing on a section of a tune if
                  I can change things up a bit. Originally only the Polish mode
                  was available, but making the other modes available was
                  relatively easy and they each offer a useful perspective.
                </p>
              </li>
              <li>
                <p>The Hup recordings seem out of time.</p>
                <p>
                  Check your calibration settings under Menu - Settings. Quite
                  possible that your device has a different audio lag/latency
                  than those I've tested.
                </p>
              </li>
              <li>
                <p>How do I export a Hup? </p>
                <p>
                  {" "}
                  From the Hups screen, tap on the Cloud button to the right of
                  the tune bpm
                </p>
              </li>
              <li>
                <p>How do I import a snap? </p>
                <p>
                  {" "}
                  From the Hups screen, tap on the Import button under the Tune
                  Volume control.{" "}
                </p>
              </li>
            </ol>
          </div>
          <div className="col-lg-6">
            <h3 className="font-weight-light">Known Issues</h3>
            <ul>
              <li>
                The app crashes sometimes if you plug or unplug headphones.
              </li>
              <li>Track volumes are not saved for particlar tunes</li>
              <li>
                The tone tracks don't work in practice mode. (Use Play tab)
              </li>
              <li>
                There's an annoying delay between count-in ending and tune
                starting
              </li>
              <li>The lockscreen controls gets confused</li>
            </ul>
            <Zendesk
              defer
              zendeskKey={ZENDESK_KEY}
              {...setting}
              onLoaded={() => console.log("zendesk widget is loaded")}
            />
            ;
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
