import React from "react";
import { Button, Alert } from "react-bootstrap";
import img3Phase from "../assets/3phase.jpg";
function Home() {
  return (
    <div className="home">
      <div className="container">
        <div className="row  my-5">
          <div className="col-lg-8">
            <Alert variant="primary">
              <Alert.Heading>19 May 2022</Alert.Heading>
              Site maintenance in progress. Hup App launch is due at Ceol Aneas
              2022 in Nelson.
            </Alert>
            <h1 className="font-weight-light">Hup !</h1>
            <p>
              Hup is an iPhone and iPad app that makes it easy and enjoyable to
              hear yourself make progress in just 5 minutes a day. It's built
              around the observation that really short feedback loops where you
              can hear how you actually sound offer a direct path to
              improvement.
            </p>
            <ul>
              <li>
                Supercharge your practice by focussing on just one thing for 5
                minutes to get immediate results.
              </li>
              <li>
                Use richly musical back tracks to support you as you as you work
                on your rhythm, tone or technique. The recordings preovided have
                separate melody and accompaniment tracks so you can get the mix
                you want.
              </li>
              <li>
                Adjust the tempo to suit whether you're learning or improving a
                tune.
              </li>
              <li>
                Full pitch control so you can match the back track to your
                instrument. This is great for learning tunes on a Bb tin
                whistle.
              </li>
              <li>Have fun with the rhythm and tone tracks. </li>
              <li>
                Work on the same tunes as your friends and share your progress.{" "}
              </li>
            </ul>
            <div className="text-center">
              <Button variant="primary" size="lg" href="./">
                Get Hup in the App Store!
              </Button>{" "}
            </div>
          </div>
          <div className="col-lg-4">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              src={img3Phase}
              alt="wth"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
