import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import imgLogo from "../assets/logo180.png";

const Navigation = () => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand>
        <LinkContainer to="/">
          <img
            width="70px"
            height="auto"
            className="img-responsive"
            src={imgLogo}
            alt="logo"
          />
        </LinkContainer>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <LinkContainer to="/">
            <Nav.Link>Home</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/gettingStarted">
            <Nav.Link>Getting Started</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/feedback">
            <Nav.Link>Feedback</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/support">
            <Nav.Link>Support</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/privacy">
            <Nav.Link>Privacy</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/about">
            <Nav.Link>About</Nav.Link>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
