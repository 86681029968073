import React from "react";

function About() {
  return (
    <div className="about">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              src="http://placehold.it/900x400"
              alt=""
            />
          </div>
          <div className="col-lg-5">
            <h1 className="font-weight-light">About</h1>
            <img src="images/logo192.png" alt="logo" />
            <p>
              Why The Hecky Not Limited is a Not For Profit company. It's
              mission is to contribute to the magic that arises when players
              really lock into a great groove. It aims to achieve this by making
              useful practice easier and more enjoyable so that players can
              refine skills and share that improvement in their music
              community..
            </p>
            <p>
              The premise is that if you find the app useful, it would be
              reasonable to contribute towards the costs of making it available.
              Proceeds from the app will be used to keep improving it and to pay
              musicians for content. If you can't afford it, no worries - you
              still get 10 minutes each day.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
