import React from "react";
import { Button, Alert } from "react-bootstrap";

import "./styles.css";
import YoutubeEmbed from "./YoutubeEmbed";

function GettingStarted() {
  return (
    <div className="gettingStarted">
      <div className="container">
        <div className="row align-items-start my-5">
          <div className="col-lg-5">
            <Alert variant="primary">
              <Alert.Heading>19 May 2022</Alert.Heading>
              This content is out of date, and will soon be refreshed with
              shorter and better content.
            </Alert>
            <h1 className="font-weight-light">Getting Started</h1>
            <h3 className="font-weight-light">The short version...</h3>
            <p>
              There's a 3 minute video of how to get started. It covers
              downloading tunes and how to unlock features.
            </p>
            <div className="text-center">
              <Button variant="primary" size="lg" href="./getHupEarly">
                Get Hup Early!
              </Button>{" "}
            </div>
          </div>
          <div className="col-lg-7">
            <YoutubeEmbed embedURL="https://www.youtube.com/embed/uVyQrcKTok8" />
          </div>
        </div>
        <div className="row align-items-center my-5">
          <div className="col-lg-5">
            <h3 className="font-weight-light">The longer version...</h3>
            <p>
              If you have a little bit of time and want to understand the
              motivation behind HUP, have a look at the videos on youtube. They
              cover the ideas, how the app works, and some ways it can be used.
              There's a bit of juice in each of them, but I will be refreshing
              them with shorter content in the near future.
            </p>

            <ul>
              <li>Part 1. Introduction - What's the target..</li>
              <li>Part 2. Quicks start to navigation and features.</li>
              <li>Part 3. Listen Mode</li>
              <li>Part 4. Three Phase Loops - Listen, Record, Review</li>
              <li>Part 5. Two Phase Loops -</li>
              <li>Part 6. Single Phase Loops - Serious Fun</li>
              <li>Part 7. Putting it together - Snips and Snaps</li>
            </ul>
          </div>
          <div className="col-lg-7">
            <YoutubeEmbed embedURL="https://www.youtube.com/embed/videoseries?list=PLmVgTor2LOfSSNc5HVNqMgQI9e7KCpqKJ" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GettingStarted;
