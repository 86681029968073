import React, { useState,  } from "react";

import {RequestBetaForm} from "./"

import img3Phase from '../assets/3phase.jpg';


function GetHupEarly() {    
  const [sent, setSent] = useState(false);

  function updateSent(value){
    setSent(value)
  }
  return (
    <div className="getHupEarly">
      <div className="container">
        <div className="row  my-5">
          <div className="col-lg-8">
            <h1 className="font-weight-light">Get Hup Early - Beta!</h1>
            <ul>
              <li>
                Hup is under active development. Right now, it's in beta - use
                it, enjoy it. It works, but it's a bit raw in places. For
                example plugging in and unplugging headphones can cause it to
                crash and you'll need to restart it.
              </li>
              <li>
                iPhone and iPad are the only platforms currently supported.
                Android support is scheduled for the near future.
              </li>
              <li>Let me know how you think Hup could be improved.</li>
              <li>
                I especially want to hear about anything you find a bit
                annoying.
              </li>
              <li>
                For beta apps, Apple have an app called TestFlight which you
                will need to have installed.
              </li>
            </ul>
            <div>
              {!sent ? (
                <RequestBetaForm updateSentCallback={updateSent}/>
              ) : (
                <div className="text-center">
                <h2>Awesome. I'll be in touch shortly.</h2>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              src={img3Phase}
              alt="screenshot"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default GetHupEarly;
