import React from "react"

function Footer() {
	if (true) {
		return(null);
	}
	else {
    return(
        <div className="footer">
            <footer className="py-3 bg-dark fixed-bottom">
                <div className="container">
                    <p className="m-0 text-center text-white">
                    Copyright &copy; WhyTheHeckyNot 2021
                    </p>
                </div>
            </footer>    
        </div>
    );
	}
}
export default Footer;
