import React, { useMemo } from "react";
import axios from "axios";
import * as Survey from "survey-react";
import "survey-react/survey.css";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
function FeedbackForm() {
  var feedbackJSON = {
    pages: [
      {
        elements: [
          {
            type: "rating",
            name: "satisfaction",
            title: "How satisfied are you with the Product?",
            isRequired: true,
            mininumRateDescription: "Not Satisfied",
            maximumRateDescription: "Completely satisfied",
          },
          {
            type: "comment",
            name: "suggestions",
            title: "What would make you more satisfied with the Product?",
          },
          {
            type: "matrix",
            name: "Features",
            title:
              "Please indicate how useful you found the following features",
            columns: [
              {
                value: 1,
                text: "Not useful",
              },
              {
                value: 2,
                text: "Don't Know",
              },
              {
                value: 3,
                text: "Useful",
              },
              {
                value: 4,
                text: "Very Useful",
              },
            ],
            rows: [
              {
                value: "tempo_pitch",
                text: "Ability to change tempo or pitch",
              },
              {
                value: "iteration",
                text: "Automatic play-review iteration",
              },
              {
                value: "awareness",
                text: "Improved awareness of what's going on in the tunes",
              },
              {
                value: "hotspots",
                text: "Ease of identifying which phrases need focus",
              },

              {
                value: "mixer",
                text: "Use the track mixer to suit accompaniment preferences",
              },
              {
                value: "sharing",
                text: "Sharing Hups with other players",
              },

              {
                value: "modes",
                text: "Different review modes - Learn, Polish, Play",
              },
            ],
          },
          {
            type: "radiogroup",
            name: "price",
            title: "Do you feel that the app delivers value?",
            choices: [
              "correct|Yes, the price is about right",
              "low|No, the price is too low for your product",
              "high|No, the price is too high for your product",
            ],
          },
          {
            type: "comment",
            name: "other suggestions",
            title:
              "What would you suggest to improve the experience for others?",
          },
          {
            type: "text",
            name: "email",
            title:
              "Please enter your email address in the box below if you're willing for us to contact you about your feedback.",
          },
        ],
      },
    ],
  };

  function feedbackComplete() {
    console.log("feedbackComplete Navigate somewhere now");
  }
  var model = new Survey.Model(feedbackJSON);
  useMemo(() => {
    model.completedHtml = "<H1> Yay </H1>";
    model.navigateToUrl = "./";
    console.log("got to to the useMemo part");
  }, []);

  const onComplete = async (survey, options) => {
    //Write survey results into database
    let responseString = JSON.stringify(model.data);
    console.log("About to post Feedback results: " + responseString);
    let feedbackResponse = { jsonPayload: responseString };
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/sendFeedbackResponse`,
        {
          feedbackResponse,
        }
      );
      console.log("response " + response.status + " " + response.statusText);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <Survey.Survey model={model} onComplete={onComplete} />
      <div className="text-center">
        <h2> Your feedback is greatly appreciated </h2>
      </div>
    </div>
  );
}

export default FeedbackForm;
